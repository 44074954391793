import React from 'react';

import withOnKeyDown from 'hoc/withOnKeyDown';

import { VisibilityIconProps } from 'ui-kit/icons/visibility-icon/visibility-icon.props';

const VisibilityIcon = ({ onClick, onKeyDown, style, toggled }: VisibilityIconProps) => {
    return (
        <div
            className="visibility-icon"
            style={style}
            role="button"
            onClick={onClick}
            onKeyDown={onKeyDown}
            tabIndex={0}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21 15">
                <defs>
                    <rect id="rect-1" width="21" height="15" x="0" y="0" />
                </defs>
                <g>
                    <path
                        fill={`${toggled ? 'rgb(172,185,200)' : 'rgb(200, 200, 200)'}`}
                        fillRule="evenodd"
                        d="M.00004623 7.47296903C0 5.70793756 4.77026464 1 10.4707576 1s10.46413126 4.70793756 10.47071136 6.47296903c.0065801 1.76503148-4.7702184 6.71828397-10.42153385 6.71828397-5.65131545 0-10.51984264-4.9532525-10.51988888-6.71828397z"
                    />
                    <ellipse cx="10.5" cy="7.5" fill="rgb(255,255,255)" rx="4.5" ry="4.5" />
                    <ellipse
                        cx="10.5"
                        cy="7.5"
                        fill={`${toggled ? 'rgb(26,73,127)' : 'rgb(172,185,200)'}`}
                        rx="2.5"
                        ry="2.5"
                    />
                </g>
            </svg>
        </div>
    );
};

export default withOnKeyDown(VisibilityIcon);
