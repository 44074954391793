import React, { useState } from 'react';

import Text from 'ui-kit/text/text';

import VisibilityIcon from 'ui-kit/icons/visibility-icon/visibility-icon';

import { PasswordTextProps } from 'ui-kit/password-text/password-text.props';

const PasswordText = ({ showErrorsText = false, type, ...rest }: PasswordTextProps) => {
    const [showText, setShowText] = useState(false);

    return (
        <div className={`password-text`}>
            <Text
                type={showText ? 'text' : type}
                showErrorsText={showErrorsText}
                {...rest}
                icon={
                    <VisibilityIcon
                        toggled={showText}
                        style={{
                            cursor: 'pointer',
                            height: '2rem',
                            width: '2rem',
                            position: 'absolute',
                            top: '12px',
                            right: '1rem'
                        }}
                        onClick={() => setShowText(!showText)}
                    />
                }
            />
        </div>
    );
};

export default PasswordText;
