import React, { useMemo, useState } from 'react';
// import { useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';

import Button from 'ui-kit/button/button';
import FormSelect from 'ui-kit/form-select/form-select';
import Text from 'ui-kit/text/text';

import { SECURITY_QUESTION_ANSWER_SCHEMA } from 'schema/security-question';
// import { accountStateSelector } from 'state/account/account.selectors';

import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { SelectOptionProps } from 'ui-kit/select/select.props';

import { SecurityQuestionFormProps, SecurityQuestionFormValues } from './security-questions-form.props';

export const SecurityQuestionSuccessModalContent = ({ translation }: { translation: TFunction<string> }) => {
    return (
        <BirdiModalContent
            icon="success"
            title={translation('modals.securityQuestionSuccess.title')}
            body={translation('modals.securityQuestionSuccess.body')}
        />
    );
};

export const SecurityQuestionFailureModalContent = ({ translation }: { translation: TFunction<string> }) => {
    return (
        <BirdiModalContent
            icon="alert"
            title={translation('modals.securityQuestionFailure.title')}
            body={translation('modals.securityQuestionFailure.body')}
        />
    );
};

const SecurityQuestionsForm = ({
    accountSecurity,
    isSubmittingSecurityQuestionAnswer,
    onSubmit
}: SecurityQuestionFormProps) => {
    const { t } = useTranslation();
    const formName = 'SecurityQuestionsForm';
    const [questionKey, setQuestionKey] = useState<string | undefined>(undefined);
    const [question, setQuestion] = useState<string | undefined>(undefined);
    // const { isSubmittingSecurityQuestions } = useSelector(accountStateSelector);

    const handleSubmitQuestionsForm = ({ answer }: Partial<SecurityQuestionFormValues>) => {
        onSubmit({ answer, questionKey, question });
    };

    const questionOptions = useMemo(() => {
        return [
            {
                key: 'passwordAnswer1',
                label: accountSecurity.passwordQuestion1,
                value: accountSecurity.passwordQuestion1
            },
            {
                key: 'passwordAnswer2',
                label: accountSecurity.passwordQuestion2,
                value: accountSecurity.passwordQuestion2
            },
            {
                key: 'passwordAnswer3',
                label: accountSecurity.passwordQuestion3,
                value: accountSecurity.passwordQuestion3
            }
        ];
    }, [accountSecurity]);

    const handleQuestionSelectionChange = ({ option: { key, value } }: { option: SelectOptionProps<string> }) => {
        setQuestionKey(key);
        setQuestion(value);
    };

    return (
        <Container fluid>
            <Row>
                <Col className="d-flex flex-column align-items-center">
                    <p>{t('components.securityQuestions.labels.formInstructions')}</p>
                    <Formik<Partial<SecurityQuestionFormValues>>
                        onSubmit={handleSubmitQuestionsForm}
                        validationSchema={SECURITY_QUESTION_ANSWER_SCHEMA}
                        initialValues={{
                            answer: undefined,
                            question: undefined
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="security-questions-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Field
                                    id="security-questions"
                                    name="question"
                                    onChange={handleQuestionSelectionChange}
                                    options={questionOptions}
                                    placeholder={t('components.securityQuestions.labels.question')}
                                    component={FormSelect}
                                />
                                <Text
                                    label={t('components.securityQuestions.labels.answer')}
                                    name="answer"
                                    onChange={formik.handleChange}
                                    errors={
                                        formik.errors?.answer
                                            ? t('components.securityQuestions.errors.answer')
                                            : undefined
                                    }
                                    touched={formik.touched.answer}
                                    value={formik.values?.answer}
                                    defaultValue={formik.initialValues?.answer}
                                />
                                <div className="d-flex flex-column align-items-center">
                                    <Button
                                        async
                                        label={t('forgotPassword.labels.submit')}
                                        type="submit"
                                        dataGAFormName={formName}
                                        isBusy={isSubmittingSecurityQuestionAnswer}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default SecurityQuestionsForm;
