import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import {
    accountFetchSecurityQuestionsRoutine,
    accountResetPasswordRoutine,
    accountSubmitSecurityQuestionAnswerRoutine
} from 'state/account/account.routines';
import { accountStateSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { logout } from 'state/account/account.reducers';

import { ResetPasswordFormValues } from 'types/forgot-password';

import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import ForgotPasswordForm, {
    ForgotPasswordFailureModalContent
} from 'components/forgot-password-form/forgot-password-form.component';
import { ForgotPasswordFormValues } from 'components/forgot-password-form/forgot-password-form.props';
import SecurityQuestionsForm, {
    SecurityQuestionFailureModalContent
} from 'components/security-questions-form/security-questions-form.component';
import { SecurityQuestionFormValues } from 'components/security-questions-form/security-questions-form.props';
import ResetPasswordForm, {
    ResetPasswordSuccessModalContent
} from 'components/reset-password-form/reset-password-form.component';

const ForgotPassword = (): ReactElement => {
    const [hasSubmittedSecurityQuestion, setHasSubmittedSecurityQuestion] = useState<boolean>(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        isFetchingSecurityQuestions,
        isSubmittingPasswordReset,
        isSubmittingSecurityQuestionAnswer,
        accountSecurity
    } = useSelector(accountStateSelector);

    const imageData = useStaticQuery(graphql`
        query {
            blueSkyBackground: file(relativePath: { eq: "assets/images/bird-on-white-background.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    const handleSubmitForgotPasswordForm = ({ username }: ForgotPasswordFormValues) => {
        dispatch(
            accountFetchSecurityQuestionsRoutine.trigger({
                username,
                onSuccess: () => {},
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <ForgotPasswordFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.forgotPasswordFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handleSubmitSecurityQuestionForm = ({ answer, question, questionKey }: SecurityQuestionFormValues) => {
        if (!question || !questionKey) return;

        dispatch(
            accountSubmitSecurityQuestionAnswerRoutine.trigger({
                accountSecurity: {
                    ...accountSecurity,
                    [questionKey]: answer
                },
                onSuccess: () => {
                    //  render the password reset components
                    setHasSubmittedSecurityQuestion(true);
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <SecurityQuestionFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.securityQuestionFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handleSubmitResetPasswordForm = (values: ResetPasswordFormValues) => {
        dispatch(
            accountResetPasswordRoutine.trigger({
                accountSecurity: {
                    ...accountSecurity,
                    newPassword: values.password,
                    shortToken: values.resetToken
                },
                onSuccess: () => {
                    dispatch(
                        openModal({
                            showClose: false,
                            onClose: () => navigate('/sign-in'),
                            bodyContent: <ResetPasswordSuccessModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.resetPasswordSuccess.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        navigate('/sign-in');
                                    }
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <ResetPasswordSuccessModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.resetPasswordFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    useEffect(() => {
        return () => {
            dispatch(logout());
        };
    }, [dispatch]);

    return (
        <WorkflowLayout
            backgroundImage={imageData.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('forgotPassword.labels.title') }}
        >
            <WorkflowLayoutFormWrapper
                eyebrowText={t('forgotPassword.labels.eyebrowText')}
                title={t('forgotPassword.labels.title')}
            >
                {!hasSubmittedSecurityQuestion && (!accountSecurity || !accountSecurity.uid) && (
                    <ForgotPasswordForm
                        onSubmit={handleSubmitForgotPasswordForm}
                        isFetchingSecurityQuestions={isFetchingSecurityQuestions}
                    />
                )}
                {!hasSubmittedSecurityQuestion && accountSecurity && accountSecurity.uid && (
                    <SecurityQuestionsForm
                        onSubmit={handleSubmitSecurityQuestionForm}
                        accountSecurity={accountSecurity}
                        isSubmittingSecurityQuestionAnswer={isSubmittingSecurityQuestionAnswer}
                    />
                )}
                {hasSubmittedSecurityQuestion && (
                    <ResetPasswordForm
                        onSubmit={handleSubmitResetPasswordForm}
                        isSubmittingPasswordReset={isSubmittingPasswordReset}
                    />
                )}
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default ForgotPassword;
