import React, { ReactElement } from 'react';
import { Form, Formik } from 'formik';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import { ResetPasswordFormValues } from 'types/forgot-password';
import { RESET_PASSWORD_SCHEMA } from 'schema/reset-password';

import PasswordRules from 'components/password-rules/password-rules.component';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import Button from 'ui-kit/button/button';
import PasswordText from 'ui-kit/password-text/password-text';
import Text from 'ui-kit/text/text';

import { ResetPasswordProps } from './reset-password-form.props';

export const ResetPasswordSuccessModalContent = ({ translation }: { translation: TFunction<string> }) => {
    return (
        <BirdiModalContent
            icon="default"
            title={translation('modals.resetPasswordSuccess.title')}
            body={translation('modals.resetPasswordSuccess.body')}
        />
    );
};

export const ResetPasswordFailureModalContent = ({ translation }: { translation: TFunction<string> }) => {
    return (
        <BirdiModalContent
            icon="alert"
            title={translation('modals.resetPasswordFailure.title')}
            body={translation('modals.resetPasswordFailure.body')}
        />
    );
};

const ResetPasswordForm = ({ isSubmittingPasswordReset, onSubmit, style }: ResetPasswordProps): ReactElement => {
    const { t } = useTranslation();

    const handleSubmit = (values: Partial<ResetPasswordFormValues>) => {
        onSubmit(values as ResetPasswordFormValues);
    };
    return (
        <Container fluid style={style}>
            <Row>
                <Col className="d-flex flex-column align-items-center">
                    <p>{t('changePassword.labels.formInstructions')}</p>
                    <Formik<Partial<ResetPasswordFormValues>>
                        onSubmit={handleSubmit}
                        validationSchema={RESET_PASSWORD_SCHEMA}
                        initialValues={{
                            resetToken: undefined,
                            password: undefined,
                            passwordConfirm: undefined
                        }}
                    >
                        {(formik: any) => (
                            <Form id="reset-password-form" onSubmit={formik.handleSubmit} autoComplete="off">
                                <Row>
                                    <Col>
                                        <Text
                                            name="resetToken"
                                            label={t('forms.resetPassword.labels.resetToken')}
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.resetToken
                                                    ? t(`forms.resetPassword.errors.resetToken`)
                                                    : undefined
                                            }
                                            touched={formik.touched.resetToken}
                                            value={formik.values?.resetToken}
                                            footNote={
                                                <small>{t('forms.resetPassword.labels.resetTokenFootnote')}</small>
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PasswordText
                                            name="password"
                                            label={t('registration.password')}
                                            type="password"
                                            onChange={formik.handleChange}
                                            touched={formik.touched.password}
                                            value={formik.values?.password}
                                            errors={formik.errors?.password}
                                        />
                                        <PasswordRules
                                            errors={formik.errors?.password}
                                            touched={formik.touched.password}
                                            password={formik.values?.password}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PasswordText
                                            name="passwordConfirm"
                                            label={t('registration.passwordConfirm')}
                                            type="password"
                                            onChange={formik.handleChange}
                                            errors={
                                                formik.errors?.password || formik.errors?.passwordConfirm
                                                    ? t(`registration.errors.passwordConfirm`)
                                                    : undefined
                                            }
                                            touched={formik.touched.passwordConfirm || formik.touched.password}
                                            value={formik.values?.passwordConfirm}
                                            showErrorsText={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-column align-items-center">
                                        <Button
                                            async
                                            label={t('changePassword.labels.submit')}
                                            variant="primary"
                                            type="submit"
                                            isBusy={isSubmittingPasswordReset}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default ResetPasswordForm;
